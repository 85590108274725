<template>
  <FormSelect
    label="Region"
    :values="values"
    :options="options"
    :rules="[VALIDATION.requiredArray]"
    :loading="loading"
    :disabled="loading"
    :multiple="multiple"
    @updateStore="onUpdateStore"
    @click="onUpdateStore"
  />
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'
import { FormSelect } from '@/components/inputs'
import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  name: 'SelectRegions',
  inheritAttrs: true,
  components: {
    FormSelect
  },
  props: {
    values: {
      type: [String, Number, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { data, error, loading, get } = fetchData()
    const { showToast } = Toast()
    const options = ref([])

    const onUpdateStore = data => {
      emit('updateStore', data)
    }

    onBeforeMount(() => {
      get('v1/user/region-access')

      watch([data, error, loading], () => {
        if (!loading.value) {
          if (error?.value) {
            showToast('There was a problem fetching stores.', 'danger')
          } else if (data?.value) {
            options.value = data?.value?.map(item => ({
              label: item?.region_name,
              value: item?.region_id
            }))
          }
        }
      })
    })

    return { options, loading, VALIDATION, onUpdateStore }
  }
}
</script>
